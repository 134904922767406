import React from "react";
import { RouteProps } from "react-router-dom";

interface IProps extends RouteProps {
}

export const DataKonekta: React.FC<IProps> = (props) => {
    return (
        <div>Hello</div>
    );
}