import React from "react";
import * as CONSTANTS from 'containers/konekta/redux/constants';
import { FormGroup, FormLabel, TextField } from "@mui/material";
import { MessageBar, MessageBarType } from 'office-ui-fabric-react';
import { Spinner, SpinnerSize } from 'office-ui-fabric-react/lib/Spinner';
import { createTheme, ThemeProvider } from "@mui/material";
import { Link } from 'office-ui-fabric-react/lib/Link';
import { AppState } from 'app.types';
import { connect } from "react-redux";
import { UserAuditTrail } from "../../utils/wcaApiTypes";
import Moment from 'moment';

import { getUserAuditTrailData, clearUserAuditTrailState } from "./redux/actions";
import MUIDataTable, { MUIDataTableColumnDef } from "mui-datatables";

interface IAppProps {
}

type AppProps = IAppProps & ReturnType<typeof mapStateToProps> & typeof mapDispatchToProps;

type AppStates = {
    dataLoaded: boolean,
    pageError: string | undefined

}

export class UserAuditTrailTable extends React.Component<AppProps, AppStates> {
    constructor(props: AppProps) {
        super(props);

        this.state = {
            dataLoaded: false,
            pageError: undefined
        }
    }


    componentDidMount(): void {
        const { actionstepContext } = this.props;
        this.props.getUserAuditTrailData(actionstepContext!.orgKey, actionstepContext!.matterContext!.id);
    }



    static getDerivedStateFromProps(nextProps: AppProps, prevState: AppStates): AppStates {
        let nextState = {} as AppStates;

        if (nextProps.gotResponse) {
            switch (nextProps.requestType) {
                case CONSTANTS.GET_USER_AUDIT_TRAIL_DATA_REQUESTED: {
                    nextState.dataLoaded = true;
                    if (nextProps.success) {
                        nextProps.clearUserAuditTrailState();
                    }
                    break;
                }
                default:
                    return nextState;
            }
        }
        return nextState;
    }

    render() {
        const { userAuditTrailDataResponse } = this.props;
        const { dataLoaded, pageError } = this.state;

        const columns = [
            {
                name: "Id",
                options: {
                    filter: false,
                    display: "excluded" as any,
                    print: false,
                    download: false
                }
            },
            {
                name: "Matter",
                options: {
                    filter: false
                }
            },
            {
                name: "Integration",
                options: {
                    filter: true,
                    setCellProps: () => ({ style: { minWidth: "60px", maxWidth: "90px" } })
                }
            },
            {
                name: "Integration Link",
                options: {
                    filter: true,
                    setCellProps: () => ({ style: { minWidth: "100px", maxWidth: "120px" } })
                }
            },
            {
                name: "Description",
                options: {
                    filter: false,
                    setCellProps: () => ({ style: { minWidth: "100px", maxWidth: "250px", fontWeight: 200 } })
                }
            },
            {
                name: "Entered On",
                options: {
                    setCellProps: () => ({ style: { minWidth: "60px", maxWidth: "80px" } }),
                    filter: true,
                    filterType: "custom",
                    customFilterListOptions: {
                        render: (v: any[]) => {
                            if (v[0] && v[1]) {
                                return `From ${v[0]} To ${v[1]}`;
                            } else if (v[0]) {
                                return `From ${v[0]}`;
                            } else if (v[1]) {
                                return `To ${v[1]}`;
                            }
                            return false;
                        },
                        update: (
                            filterList: { [x: string]: never[] },
                            filterPos: number,
                            index: string | number
                        ) => {
                            console.log(
                                "customFilterListOnDelete: ",
                                filterList,
                                filterPos,
                                index
                            );

                            if (filterPos === 0) {
                                filterList[index].splice(filterPos, 1);
                            } else if (filterPos === 1) {
                                filterList[index].splice(filterPos, 1);
                            } else if (filterPos === -1) {
                                filterList[index] = [];
                            }

                            return filterList;
                        }
                    },
                    filterOptions: {
                        dates: [],
                        logic(date: number, filters: number[]) {
                            if (filters[0] && filters[1]) {
                                return date < filters[0] || date > filters[1];
                            } else if (filters[0]) {
                                return date < filters[0];
                            } else if (filters[1]) {
                                return date > filters[1];
                            }
                            return false;
                        },
                        display: (
                            filterList: { [x: string]: any },
                            onChange: (arg0: any, arg1: any, arg2: any) => void,
                            index: React.ReactText,
                            column: any
                        ) => (
                            <div>
                                <FormLabel>Enter On</FormLabel>
                                <FormGroup row>
                                    <TextField
                                        type="date"
                                        placeholder="from"
                                        value={filterList[index][0] || ""}
                                        onChange={(event: { target: { value: any } }) => {
                                            filterList[index][0] = event.target.value;
                                            onChange(filterList[index], index, column);
                                        }}
                                        style={{ width: "60%", marginRight: "5%" }}
                                    />
                                    <TextField
                                        type="date"
                                        placeholder="to"
                                        value={filterList[index][1] || ""}
                                        onChange={(event: { target: { value: any } }) => {
                                            filterList[index][1] = event.target.value;
                                            onChange(filterList[index], index, column);
                                        }}
                                        style={{ width: "60%" }}
                                    />
                                </FormGroup>
                            </div>
                        )
                    },
                    print: false
                }
            },
            {
                name: "Entered By",
                options: {
                    filter: true,
                }
            },
            {
                name: "Tag",
                options: {
                    filter: true
                }
            }
        ] as MUIDataTableColumnDef[];

        const getMuiTheme = createTheme({
            typography: {
                body1: {
                    fontSize: "12px"
                },
                body2: {
                    fontSize: "12px"
                },
                h6: {
                    fontSize: "14px",
                    fontWeight: 700
                }
            },
            components: {
                MuiTableRow: {
                    styleOverrides: {
                        root: {
                            fontWeight: "lighter",
                            fontSize: "12px"
                        }
                    }
                },
                MuiTableCell: {
                    styleOverrides: {
                        root: {
                            fontWeight: "lighter",
                            fontSize: "12px",
                            padding: "2px"
                        },
                        head: {
                            fontWeight: 700
                        }
                    }
                },
                MuiMenuItem: {
                    styleOverrides: {
                        root: {
                            fontSize: "12px"
                        }
                    }
                },
                MuiFormLabel: {
                    styleOverrides: {
                        root: {
                            fontSize: "12px"
                        }
                    }
                },
                MuiInputBase: {
                    styleOverrides: {
                        root: {
                            fontSize: "12px"
                        }
                    }
                },
                MuiGrid: {
                    styleOverrides: {
                        "grid-xs-6": {
                            maxWidth: "33.33%",
                        },
                        item: {
                            margin: 0,
                            padding: "8px !important"
                        }
                    }
                },
                MuiFormControl: {
                    styleOverrides: {
                        root: {
                            width: "100% !important"
                        }
                    }
                },
                //@ts-ignore
                MUIDataTableFilter: {
                    styleOverrides: {
                        root: {
                            padding: "20px"
                        },
                        "gridListTile": {
                            marginTop: 0
                        }
                    }
                },
                //@ts-ignore
                MUIDataTableBodyRow: {
                    styleOverrides: {
                        root: {
                            '&:nth-child(odd)': {
                                backgroundColor: '#E0E0E0'
                            }
                        }
                    }
                }
            }

        });

        const options = {
            filter: true,
            selectableRows: "none" as any,
            filterType: "multiselect" as any,
            responsive: "scrollMaxHeight" as any,
            print: false,
            disableToolbarSelect: true as any,
            rowsPerPage: 10 as number,
            rowsPerPageOptions: [10, 20, 50, 100],
            rowStyle: {
                fontSize: 12,
            },
            downloadOptions: { filename: 'userAuditTrail.csv', separator: ',' }
        };

        return (
            <div>
                {pageError ?
                    <div>
                        <MessageBar messageBarType={MessageBarType.error} isMultiline={false} >
                            {pageError}
                        </MessageBar>
                        <div>
                            <p className="text-danger">
                                Please <Link onClick={() => window.location.reload()} >refresh the page</Link> and try again. If you continue to experience problems, please
                                <Link href={"https://support.konekta.com.au/support/tickets/new"} target="_blank" rel="noopener noreferrer"> submit a ticket</Link>.
                            </p>
                        </div>
                    </div>
                    : <div>
                        {dataLoaded ?
                            <div>
                                <ThemeProvider theme={getMuiTheme}>
                                    <MUIDataTable
                                        title={"User Audit Trail"}
                                        data={userAuditTrailDataResponse ? userAuditTrailDataResponse!.data!.map((userData: UserAuditTrail) => {
                                            return [
                                                userData.id,
                                                userData.matterId,
                                                userData.integration,
                                                userData.integrationLink,
                                                userData.description,
                                                /*   userData.description!.split("<br/>").map((line: string) => {                           
                                                       return <p style={{ margin:"0 0 0 0 "}}>{line}</p>;
                                                   }),  */
                                                Moment.utc(Moment(userData.dateTimeCreatedUtc)).local().format('YYYY-MM-DD HH:mm'),
                                                userData.enteredByFirstName + " " + userData.enteredByLastName,
                                                userData.tag
                                            ]
                                        })
                                            : []}
                                        columns={columns}
                                        options={options}
                                    />
                                </ThemeProvider>
                            </div>
                            : <Spinner label={"... retrieving Data"} size={SpinnerSize.large} />
                        }
                    </div>}
            </div>
        );
    }
}

const mapStateToProps = (state: AppState) => {
    return {
        gotResponse: state.userAuditTrail.gotResponse,
        requestType: state.userAuditTrail.requestType,
        error: state.userAuditTrail.error,
        success: state.userAuditTrail.success,
        userAuditTrailDataResponse: state.userAuditTrail.getUserAuditTrailDataResponse,
        actionstepContext: state.common.actionstepContext
    }
}

const mapDispatchToProps = {
    getUserAuditTrailData,
    clearUserAuditTrailState
}

export default connect(mapStateToProps, mapDispatchToProps)(UserAuditTrailTable);